/* ------------------------------------------------------------------------ */
/* hugo-tufte.css */
/* Contains extensions to the original tufte.css styles to */
/* accomodate a blog-like site. */
/* ------------------------------------------------------------------------ */

@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@351&display=swap');

/* When setting the primary font stack, apply it to the Pure grid units along */
/* with `html`, `button`, `input`, `select`, and `textarea`. Pure Grids use */
/* specific font stacks to ensure the greatest OS/browser compatibility. */
html,
button,
input,
select,
textarea,
p,
nav,
section,
article,
header,
footer,
.pure-g [class*='pure-u'] {
    font-family: 'et-book', -apple-system, 'San Francisco', 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue',
        'Lucida Grand', sans-serif;
}

p {
    text-align: justify;
}

code {
    font-family: 'Fira Code', Consolas, 'Liberation Mono', Menlo, Courier,
        monospace;
    font-size: 1.125rem;
    line-height: 1.6;
}

$ht-code-border-radius: 0.4em;
.highlight {
    width: 55%;
    overflow-x: scroll;
    border-radius: $ht-code-border-radius;

    & > div.chroma > table.lntable {
        margin: $ht-code-border-radius 0 $ht-code-border-radius 0;
        & td:first-of-type {
            & span:not(& span > span) {
                padding: 0 0.75em 0 0.5em;
            }
        }
    }
    & > .chroma > code {
        width: max-content;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        margin-left: 0.5em;
        & > span.hl {
            margin-left: -0.5em;
            padding-left: 0.5em;
        }
    }

    .chroma .hl {
        // border-radius: $ht-code-border-radius / 2;
        display: block;
    }

    &::-webkit-scrollbar {
        border-radius: $ht-code-border-radius;
        width: 10px;
        height: 1rem;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: $ht-code-border-radius;
    }
}

.highlight .lntable {
    overflow: initial;
}

.highlight pre {
    margin: 0;
}

.highlight pre code {
    display: block;
    font-size: 1rem;
}

.sidenote,
.marginnote {
    margin-right: -55%;
    width: 45%;
}

/* ------------------------------------------------------------------------ */
/* Brand details, such as a sidebar or top display. */
/* ------------------------------------------------------------------------ */
header.brand {
    margin-top: 0.5em;
    width: 95%;
    max-width: 45rem;
}

/* Main brand title */
header.brand h1 {
    margin: 0;
    font-weight: 400;
    color: rgba(65, 70, 75, 1);
}

header.brand h2 {
    margin: 0;
    padding-top: 0rem;
    /*font-style: normal;*/
    /*font-weight: 200;*/
    color: rgba(100, 105, 110, 1);
}

header.brand hr {
    text-align: left;
    margin-left: 0;
    // width: 75%;
    border: 3px solid rgba(250, 250, 250, 0.25);
}

nav.menu ul {
    list-style: none;
    display: block;
    /*text-align:center;*/
    margin-top: 0.75rem;
    padding: 0;
    max-width: 45rem;
    /* Width is the same as tufte.css body */
    font-size: 0.9rem;
    width: 87.5%;
}

nav.menu li {
    display: inline-block;
    margin-right: 1rem;
}

nav.menu li a {
    text-decoration: none;
    background: transparent;
    color: rgba(65, 70, 75, 1);
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

nav.menu li a:hover,
nav.menu li a:active,
nav.menu li a:focus {
    background: inherit;
    color: darkgray;
}

/* Adds dark mode */
@media (prefers-color-scheme: dark) {
    header.brand h1 {
        color: #999;
    }
    header.brand h2 {
        color: #999;
    }
    nav.menu li a {
        color: #999;
    }
    header.brand hr {
        border-color: rgba(90, 90, 90, 0.25);
    }
}

/* ------------------------------------------------------------------------ */
/* Generic content, such as the index list pages */
/* ------------------------------------------------------------------------ */

/* Content Title styling.  This is mostly to avoid underlying links. */
h1.content-title {
    /*max-width: 50rem;*/
}
h1.content-title a:link,
h1.content-title a:visited {
    background: transparent;
    text-decoration: none;
    color: inherit;
}
h1.content-title a:hover,
h1.content-title a:focus {
    color: darkgray;
}

/* Content meta-data such as author, publication date, etc. */
.content-meta {
    display: block;
    /*color: rgba(155, 155, 155, 1);*/
    color: rgba(100, 105, 110, 1);
    font-size: 1.1rem;
    margin-top: 1em;
}

.content-meta .author {
    /*color: rgb(90, 20, 55)*/
    color: rgba(65, 70, 75, 1);
}

.post-avatar {
    border-radius: 50px;
    float: right;
    margin-left: 1em;
}

/* ------------------------------------------------------------------------ */
/* Styling for listing pages. */
/* ------------------------------------------------------------------------ */
.list-page {
    ul {
        list-style-type: none;
        margin: -0.25em;
        width: 87.5%;
        max-width: 45rem;
    }
    li {
        margin: 0;
        /*font-size: 95%;*/
    }
}
.list-page .list-date {
    display: inline;
    font-size: 0.75em;
    /* padding-right: 2em; */
    /* margin-right: 2em; */
}

/* Table of Contents */
.toc summary {
    font-size: 1.5rem;
    margin-bottom: -1.5rem;
    padding-left: 0;
}

.toc {
    /*float: right;*/
    /*padding: 0rem 1rem 1rem 1rem;*/
    /*margin-top: 1rem;*/
    /*border-left: 1px solid #eee;*/
}

.toc ul {
    list-style: none;
    display: block;
    /*margin-top: 0.75rem;*/
    padding: 0;
    width: 87.5%;
}

.toc li {
    line-height: 0.5rem;
    margin: 1rem;
}

table:not(.lntable) {
    margin-top: 1em;
    font-size: 1.4rem;
    width: auto; /* making booktabs style tables the unstyled default in case someone uses Markdown styling */
    /* margin: 0 auto; */
    /* border-spacing: 0px; */
    border-top: 2px solid #111;
    border-bottom: 2px solid #111;
}

table:not(.lntable) th,
table:not(.lntable) td {
    font-size: 1.25rem;
    line-height: 1.71428571;
}

table:not(.lntable) td {
    padding-right: 0.75em;
}

table.lntable {
    td.lntd {
        padding: 0em;
    }
    border-spacing: 0;
    padding: 0;
}

/* Footer, but with a different name to avoid conflicts with tufte.css */
footer.page-footer {
    padding-top: 1em;
    margin-top: 3em;
    color: #aaa;
    width: 95%;
    max-width: 45rem;
}
footer.page-footer p {
    font-size: 1.2rem;
    margin: 0em;
    /* light font looked odd on chrome */
    /*font-weight: lighter;*/
}
footer.page-footer a {
    color: rgba(65, 70, 75, 1);
    text-decoration: none;
    background: transparent;
}
footer.page-footer hr {
    text-align: left;
    margin-left: 0;
    width: 100%;
    border: 3px solid rgba(250, 250, 250, 0.25);
}

footer.page-footer ul.page-footer-menu {
    list-style: none;
    display: block;
    /*text-align:center;*/
    margin: 0;
    padding: 0;
    width: unset;
}

footer.page-footer ul.page-footer-menu li {
    display: inline-block;
    margin-right: 0.5rem;
    // font-size: 55%;
}

.copyright {
}
.copyright p {
    font-size: 90%;
}

/* Adds dark mode */
@media (prefers-color-scheme: dark) {
    footer.page-footer hr {
        border-color: rgba(90, 90, 90, 0.25);
    }

    footer.page-footer {
        color: #666;
    }

    footer.page-footer a {
        color: #666;
    }
}

/* ------------------------------------------------------------------------ */
/* Post Archive. */
/* ------------------------------------------------------------------------ */
/* We utilize the html5 summary tags in order to create a post archive */
/* with built-in folding. */
details {
    border-radius: 3px;
}

details summary {
    vertical-align: top;
    padding: 0.3em 0.5em;
    outline: none;
    /*color: rgba(65, 70, 75, 1);*/
}

details summary.year {
    font-size: 1.5rem;
}

details[open] summary {
}

@media only screen and (max-width: 767px) {
    body {
        // width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;

        p {
            text-align: initial;
        }
    }
}
